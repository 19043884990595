import type { ChatPlugin } from '#types/plugin/chat'

export default defineNuxtPlugin<{ chat: ChatPlugin }>(() => {
  return {
    provide: {
      chat: {
        open: () => console.warn('Chat is not implemented for The North Face brand.'),
        available: ref(false)
      }
    }
  }
})
